import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: () => import('../views/IndexView.vue'),
    meta: {
      title: '首页 / 碎碎念',
      keepAlive: true,
    }
  },
  /**
   * 分类列表
   */
  {
    path: '/category',
    component: () => import('../views/CategoryView.vue'),
    meta: {
      title: '分类 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 分类详情
   * 根据 categoryName 查询
   */
  {
    path: '/category/:categoryName',
    name: 'category-list',
    component: () => import('../views/CategoryList.vue'),
    meta: {
      title: '分类 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 文章详情
   */
  {
    path: '/article/:articleId',
    alias: '/search?q=:id&src=:fromUrl',
    name: 'article-detail',
    component: () => import('../views/ArticleDetail.vue'),
    meta: {
      title: '文章详情 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 登录页
   */
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '碎碎念',
      keepAlive: true
    }
  },
  /**
   * 关于我们
   */
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '关于我们 / 碎碎念',
      keepAlive: true
    }
  },
  /**
   * 友情链接
   */
  {
    path: '/friendslink',
    name: 'friendslink',
    component: () => import('../views/FriendsLink.vue'),
    meta: {
      title: '友情链接 / 碎碎念',
      keepAlive: true
    }
  },
  {
    path: '/u/:username',
    name: 'user-page',
    component: () => import('../views/user/UserPage.vue'),
    meta: {
      title: '个人中心 / 碎碎念',
    },
    // 个人中心 - 子路由
    children: [
      // 微博
      {
        path: '',
        alias: '/u/:username/weibo',
        component: () => import('../components/public/src/page/src/WeiboPage.vue'),
        meta: {
          title: '个人中心 / 碎碎念',
          tabName: 'weibo'
        },
      },
      // 媒体
      {
        path: '/u/:username/media',
        component: () => import('../components/public/src/page/src/MediaPage.vue'),
        meta: {
          title: '个人中心 / 碎碎念',
          tabName: 'media'
        },
      },
      // 喜欢
      {
        path: '/u/:username/like',
        component: () => import('../components/public/src/page/src/LikePage.vue'),
        meta: {
          title: '个人中心 / 碎碎念',
          tabName: 'like'
        },
      }
    ]
  },

  // 热搜榜
  {
    path: '/explore',
    name: 'explore',
    component: () => import('../views/HotArticleList.vue'),
    meta: {
      title: '热门文章 / 碎碎念'
    }
  },
  {
    path: '/editor/drafts/new',
    component: () => import('../views/user/ArticleEditorView.vue'),
    meta: {
      title: '写文章 / 碎碎念'
    }
  },
  {
    path: '*',
    alias: '/404',
    component: () => import('../views/404.vue'),
    meta: {
      title: '啊哦，走丢咯 / 碎碎念'
    }
  },
  {
    path: '/doc',
    component: () => import('../views/Document.vue'),
    meta: {
      title: '文档 / 碎碎念'
    }
  },
  {
    path: '/template',
    component: () => import('../views/TemplateView.vue'),
    meta: {
      title: '模板 / 碎碎念'
    }
  },
  {
    path: '/drafts',
    component: () => import('../views/user/DraftsView.vue'),
    meta: {
      title: '草稿列表 / 碎碎念'
    }
  },
  {
    path: '/editor/drafts/:id',
    name: 'DraftsDetail',
    component: () => import('../views/user/DraftsDetail.vue'),
    meta: {
      title: '编辑草稿 / 碎碎念'
    }
  },

  // 设置
  {
    path: '/setting',
    name: 'SettingView',
    component: () => import('../views/SettingView.vue'),
    meta: {
      title: '设置 / 碎碎念'
    },
    // 子路由
    children: [
      // 安全设置
      { path: '', alias: '/setting/security', component: () => import('@/components/public/src/setting/Security.vue') },
      // 隐私设置
      { path: '/setting/privacy', component: () => import('@/components/public/src/setting/Privacy') },
      // 个性化设置
      { path: '/setting/personal', component: () => import('@/components/public/src/setting/Personal') },
      // 切换账号
      { path: '/setting/switch', component: () => import('@/components/public/src/setting/SwitchUser') },
      // 注销账号
      { path: '/setting/delete', component: () => import('@/components/public/src/setting/Delete') },
      // 退出账号
      { path: '/setting/logout', component: () => import('@/components/public/src/setting/Logout') },

      // 安全设置 - 您的账号信息
      { path: '/setting/your_data', component: () => import('@/components/public/src/setting/your_data/YourData') },
      // 安全设置 - 修改您的密码
      { path: '/setting/security/modify', component: () => import('@/components/public/src/setting/Modify') },
      // 安全设置 - 您的账号信息 - 用户名
      { path: '/setting/your_data/username', component: () => import('@/components/public/src/setting/your_data/DataUsername.vue') },
      { path: '/setting/your_data/phone', component: () => import('@/components/public/src/setting/your_data/DataPhone') },
    ]
  },
  // 编辑个人资料
  {
    path: '/setting/profile',
    component: () => import('../views/user/PersonalView.vue'),
    meta: {
      title: '个人资料 / 碎碎念'
    },
  }
  
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //路由发生改变时,触发
  // 设置页面标题
  window.document.title = undefined ? '碎碎念' : to.meta.title;
  next();
});

export default router
