import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 开发环境
    // baseURL: 'http://localhost:7777/',
    // 生产环境
    // baseURL: 'http://api.metabyte.top:7777/',
    baseURL: 'https://api.metabyte.top/',

    userInfo: null,
    loginStatus: false
  },
  getters: {},
  mutations: {
    SET_USER(state, user) {
      state.userInfo = user;
      state.loginStatus = true;
    },
    CLEAR_USER(state) {
      state.userInfo = null;
      state.loginStatus = false;
    },
    HAS_LOGINED(state) {
      state.loginStatus = true;
    }
  },
  actions: {},
})
