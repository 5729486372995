import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入bootstrap的css和js
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
// 导入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 导入moment
import moment from 'moment'
// mavon-editor
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// 自定义style.css
import './assets/css/style.css'
// 深色主题
import './assets/css/style_dark.css'

Vue.use(ElementUI)
Vue.use(mavonEditor)
Vue.config.productionTip = false

Vue.directive('set-video-volume', {
  inserted(el) {
    if (el.tagName === 'VIDEO') {
      el.volume = 0.5; // 设置音量为 50%
    }
  }
});

// 设置 moment.js 语言环境
moment.locale('zh-cn', {
  months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
  monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
  weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY-MM-DD',
    LL: 'YYYY年MM月DD日',
    LLL: 'YYYY年MM月DD日Ah点mm分',
    LLLL: 'YYYY年MM月DD日ddddAh点mm分',
    l: 'YYYY-M-D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm'
  },
  meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
  meridiemHour: function (hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (meridiem === '凌晨' || meridiem === '早上' ||
      meridiem === '上午') {
      return hour;
    } else if (meridiem === '下午' || meridiem === '晚上') {
      return hour + 12;
    } else {
      // '中午'
      return hour >= 11 ? hour : hour + 12;
    }
  },
  meridiem: function (hour, minute, isLower) {
    const hm = hour * 100 + minute;
    if (hm < 600) {
      return '凌晨';
    } else if (hm < 900) {
      return '早上';
    } else if (hm < 1130) {
      return '上午';
    } else if (hm < 1230) {
      return '中午';
    } else if (hm < 1800) {
      return '下午';
    } else {
      return '晚上';
    }
  },
  calendar: {
    sameDay: '[今天]LT',
    nextDay: '[明天]LT',
    nextWeek: '[下]ddddLT',
    lastDay: '[昨天]LT',
    lastWeek: '[上]ddddLT',
    sameElse: 'L'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
  ordinal: function (number, period) {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
        return number + '日';
      case 'M':
        return number + '月';
      case 'w':
      case 'W':
        return number + '周';
      default:
        return number;
    }
  },
  relativeTime: {
    future: '%s内',
    past: '%s前',
    s: '几秒',
    ss: '%d秒',
    m: '1分钟',
    mm: '%d分钟',
    h: '1小时',
    hh: '%d小时',
    d: '1天',
    dd: '%d天',
    M: '1个月',
    MM: '%d个月',
    y: '1年',
    yy: '%d年'
  },
  week: {
    // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
    dow: 1, // Monday is the first day of the week.
    doy: 4  // The week that contains Jan 4th is the first week of the year.
  }
})

// 数值过滤器
Vue.filter('formatNumber', function(value) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'k';
  } else {
    return value.toString();
  }
});

// 数值过滤器(每三位数字断位)
Vue.filter('formatNum', function(value) {
  if (value >= 1000) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return value.toString();
  }
});

// 时间过滤器 moment.js
Vue.filter('formatTime', function(value) {
  const now = moment();
  const publishMoment = moment(value);

  const hoursDiff = now.diff(publishMoment, 'hours');
  const daysDiff = now.diff(publishMoment, 'days');

  if (hoursDiff < 24) {
    return `${hoursDiff}小时内`;
  } else if (daysDiff < 28) {
    return `${daysDiff}天前`;
  } else {
    return publishMoment.format('YYYY-MM-DD HH:mm:ss');
  }
});

// 格式化时间 XX天前
Vue.filter('formatTimeX', function(value) {
  if (!value) return ''; // 防止传入空值时报错
  const formerTime = moment(value);
  return formerTime.startOf('day').fromNow();
});

// 日期格式化 YYYY-MM-DD hh:mm:ss -> DD:MM:YYYY
Vue.filter('formatDateDDMMYYYY', function(value) {
  if (!value) return ''; // 防止传入空值时报错
  const formerTime = moment(value);
  return formerTime.format("MMM Do YYYY");
});

// 日期格式化 YYYY-MM-DD hh:mm:ss -> YYYY MM DD
Vue.filter('formatDateYYYYMMDD', function(value) {
  if (!value) return ''; // 防止传入空值时报错
  const formerTime = moment(value);
  return formerTime.format("ll");
});

// 阅读时间计算


// 跳转后自动返回页面顶部
// router.afterEach(() => {
//   window.scrollTo(0,0);
// })

router.beforeEach((to, from, next) => {
  // 在路由切换前，将页面滚动到顶部
  window.scrollTo(0, 0);
  next();
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
